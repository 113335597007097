/* Language: css */
.row {
    display: flex;
    flex-wrap: wrap;
}
.personal {
    border-radius: 80%;
    width: 320px;   
    height: 320px;
}

.header {
    display: flex;
    justify-content: center;
    padding: 1.5em 2em;
    text-align: start;
    

    h1 {
        margin: auto;
        padding: 1.5rem;
        overflow-wrap: break-word;
        font-weight: bold;
        align-items: center;
    }
}


@media only screen and (min-width: 1280px){
    .personal {
        width: 420px;
        height: auto;
    }
}